<template>
    <div class="activ">
      <div class="hd_head">
        <div class="hd_head_fanhui">
          <van-icon name="arrow-left" @click="revert()" color="#ffffff" size="24" />
          <span>特色活动</span>
          <span></span>
        </div>
        <van-swipe class="my-swipe" :show-indicators="false" :autoplay="3000">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <div class="hd_banner" >
              <img :src="item.imgUrl" alt="">
            </div>
          </van-swipe-item>
        </van-swipe>

      </div>
      <div class="td_tiket_nav">
        <div class="td_tiket_nav_list " :class="params.code == 'lyhd'?'td_tiket_nav_list_text':''" @click="typeChange('lyhd')">
          <span>旅游活动</span>
          <div class="td_tiket_nav_color" v-if="params.code == 'lyhd'"></div>
        </div>
        <div class="td_tiket_nav_list" :class="params.code == 'whhd'?'td_tiket_nav_list_text':''" @click="typeChange('whhd')">
          <span>文化活动</span>
          <div class="td_tiket_nav_color" v-if="params.code == 'whhd'"></div>
        </div>
        <div class="td_tiket_nav_list" :class="params.code == 'mshd'?'td_tiket_nav_list_text':''" @click="typeChange('mshd')">
          <span>民俗活动</span>
          <div class="td_tiket_nav_color" v-if="params.code == 'mshd'"></div>
        </div>
      </div>
      <div class="td-brd"></div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list  v-model="loading" :finished="finished"  finished-text="没有更多了" @load="onLoad"  v-if="actives && actives.length > 0">
          <div class="active_list" v-for="(item, index) in actives" :key="index" @click="$router.push('/activDetails/' + item.contentId)">
            <div class="active_list_title">
              <span>{{item.title}}</span>
              <span v-if="item.tags">{{item.tags}}</span>
            </div>
            <div class="active_list-text" v-html="item.content"></div>
            <van-image :src="item.cover" class="ticket_img" v-if="item.cover">
                  <template v-slot:error>加载失败</template>
            </van-image>
            <div class="active_list_btn" @click="toDetail(item.contentId)">
              <span style="font-size: 14px">查看详情</span>
              <van-icon name="arrow" color="#2ED462"  size="16" />
            </div>
          </div>
        </van-list>
        <div class='no_data_img' v-else></div>
      </van-pull-refresh>
    </div>
</template>

<script src='./activ.js'>

</script>

<style scoped src="./activ.css">

</style>
